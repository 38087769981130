/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import {
    object, string, bool, func,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
// components
// eslint-disable-next-line import/no-cycle
import ConnectedHomepageABTesting from '../AB_Testing/hocs/ABTesting';
import CeoBannerUI from './Partials/Banners/CeoBannerUI';
import PassportBannerUI from './Partials/Banners/PassportBannerUI';
import SocialIconHomepageBlock from './Partials/SocialConnectedIcons/SocialiconHomepageBlock';
// not loadable because this container makes 1 check, loadable is inside this container
import TrendingProductsABContainer from '../GraphqlCommonComponents/TrendingProducts/TrendingProductsABContainer';
// helpers
import getNextNDates from '../../../helpers/getNextNDates';
import isEmptyReference from '../../../helpers/contentstack/isEmptyReference';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import isVideoFormat from '../../../helpers/isVideoFormat';

// eslint-disable-next-line import/no-cycle
import PersonalizedContent from '../EnterpriseID/PersonalizedHomepage/PersonalizedContentBlock';
// eslint-disable-next-line import/no-cycle
import OfferGatewayBlock from '../EnterpriseID/PersonalizedHomepage/OfferGatewayBlock';
import ComponentErrorBoundary from '../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import BlockHeading from '../../ContentTypeComponents/BlockHeading';
import GiftFinderABTestContainer from './Partials/GraphqlFindAGiftFast/GiftFinderABTestContainer';
import HeroSingleResponsiveAbTestContainer from '../../ContentTypeComponents/HeroSingle/HeroSingleResponsiveAbTestContainer';
import noop from '../../../helpers/noop';
import PersonalizedWidgetContainer from '../EnterpriseID/PersonalizedWidgets/PersonalizedWidgetContainer';
import RecentlyViewedRecsSFContainer from '../EnterpriseID/RecentlyViewedProducts/RecentlyViewedRecsSFContainer';
import BuyItAgainRecsSFContainer from '../EnterpriseID/BuyItAgainProducts/BuyItAgainRecsSFContainer';
import OfferGatewayBannerContainer from '../GraphqlCommonComponents/GenericDialog/OfferGateway/OfferGatewayBannerContainer';

// components
const ReactMarkdown = loadable(() => import(/* webpackChunkName: "ReactMarkdown" */ 'react-markdown'));
const GraphqlMobileCategory = loadable(() => import(/* webpackChunkName: "GraphqlMobileCategory" */ './Partials/GraphqlMobileCategory/GraphqlMobileCategory'));
const ConnectedGraphqlFindGiftFast = loadable(() => import(/* webpackChunkName: "ConnectedGraphqlFindGiftFast" */ './Partials/GraphqlFindAGiftFast/GraphqlFindAGiftFast'));
const GiftFinder = loadable(() => import(/* webpackChunkName: "ConnectedGraphqlFoodFindPerfect" */ './Partials/GraphqlFindAGiftFast/GraphqlFoodGiftFinderContainer'));
const ConnectedHeroImage = loadable(() => import(/* webpackChunkName: "ConnectedHeroImage" */ './Partials/HeroImage/HeroImage'));
const GraphqlHPTiers = loadable(() => import(/* webpackChunkName: "GraphqlHPTiers" */ './Partials/GraphqlHomepageTiers/GraphqlHPTiers'));
const HPSilos = loadable(() => import(/* webpackChunkName: "HPSilos" */ './Partials/GraphqlHPSilos/GraphqlHPSilos'));
const GraphqlSeoFooter = loadable(() => import(/* webpackChunkName: "GraphqlSeoFooter" */ './Partials/GraphqlSeoFooter/GraphqlSeoFooter'));
const GraphqlHPCategoryCarousel = loadable(() => import(/* webpackChunkName: "GraphqlHPCategoryCarousel" */ './Partials/GraphqlHPCategoryCarousel/GraphqlHPCategoryCarousel'));

// ContentTypeComponents
const HeroSingle = loadable(() => import(/* webpackChunkName: "HeroSingle" */ '../../ContentTypeComponents/HeroSingle/HeroSingle'));
const ThirdsHero = loadable(() => import(/* webpackChunkName: "ThirdsHero" */ '../../ContentTypeComponents/HeroThreeSection/HeroThreeSection'));
const InteractiveHero = loadable(() => import(/* webpackChunkName: "InteractiveHero" */ '../../ContentTypeComponents/HeroInteractive/HeroInteractive'));
const BannerGeneric = loadable(() => import(/* webpackChunkName: "BannerGeneric" */ '../../ContentTypeComponents/GenericBanner/GenericBanner'));
const ThreeUpFeature = loadable(() => import(/* webpackChunkName: "ThreeUpFeature" */ '../../ContentTypeComponents/ThreeUp/ThreeUp'));
const NUpFeature = loadable(() => import(/* webpackChunkName: "NUpFeature" */ '../../ContentTypeComponents/NUp/NUp'));
const NUpSimple = loadable(() => import(/* webpackChunkName: "NUpSimple" */ '../../ContentTypeComponents/NupSimple/NupSimple'));
const SmsSignup = loadable(() => import(/* webpackChunkName: "SmsSignup" */ '../../ContentTypeComponents/SMSSignup/SMSSignup'));
const WyngModule = loadable(() => import(/* webpackChunkName: "WyngModule" */ '../../ContentTypeComponents/Wyng/Wyng'));
const Subcollection = loadable(() => import(/* webpackChunkName: "SubCollection" */ '../../ContentTypeComponents/SubCollection/SubCollection'));
const UserGeneratedContent = loadable(() => import(/* webpackChunkName: "UserGeneratedContent" */ '../../ContentTypeComponents/UserGeneratedContent'));
const SeoBlock = loadable(() => import(/* webpackChunkName: "SeoBlock" */ '../../ContentTypeComponents/SeoBlock/SeoBlock'));
const UserReviews = loadable(() => import(/* webpackChunkName: "UserReviews" */ '../../ContentTypeComponents/UserReviews'));
const VideoModule = loadable(() => import(/* webpackChunkName: "VideoModule" */ '../../ContentTypeComponents/Video/Video'));
const LinkList = loadable(() => import(/* webpackChunkName: "LinkList" */ '../../ContentTypeComponents/LinkList/LinkList'));
const ContentCategory = loadable(() => import(/* webpackChunkName: "ContentCategories" */  '../MarketplacePage/Partials/SharedMarketplaceComponents/ContentCategoriesContainer'));
const HeroSingleResponsive = loadable(() => import(/* webpackChunkName: "PersonalizedHero" */ '../../ContentTypeComponents/HeroSingle/HeroSingleResponsive'));
const UniversalComponent = loadable(() => import(/* webpackChunkName: "UniversalComponent" */ './Partials/UniversalHomepageComponent/UniversalHomepageComponent'));
const CelebrationsMarketingBlock = loadable(() => import(/* webpackChunkName: "CelebrationsMarketingBlock" */ '../../ContentTypeComponents/CelebrationsMarketingBlock/CelebrationsMarketingBlockContainer'));
// Banner UIs
const ZoneTenUI = loadable(() => import(/* webpackChunkName: "ZoneTenUI" */ './Partials/Banners/ZoneTenUI'));

const ArticleBlock = loadable(() => import(/* webpackChunkName: "ArticleBlock" */ '../GraphqlTemplatePage/Partials/TemplateJasonwuConnect/Partials/JasonwuConnectArticleBlock'));
const PersonalizedRecentlyPurchasedContainer = loadable(() => import(/* webpackChunkName: "PersonalizedRecentlyPurchasedContainer" */ '../EnterpriseID/PersonalizedRecentlyPurchased/PersonalizedRecentlyPurchasedContainer'));
const PassportBannerHomepage = loadable(() => import(/* webpackChunkName: "PassportBannerHomepage" */ '../PassportBannerHomepage/PassportBannerHomepage'));
const HomePageMainSlider = loadable(() => import(/* webpackChunkName: "HomePageMainSlider" */ './Partials/HomepageMainSlider/HomePageMainSlider'));
const TestimonialsPageBody = loadable(() => import(/* webpackChunkName: "TestimonialsPageBody" */ './Partials/TestimonialsBlock/TestimonialsPageBody'));
const CustomerTestimonialsV2 = loadable(() => import(/* webpackChunkName: "CustomerTestimonialsV2" */ '../../ContentTypeComponents/CustomerTestimonials_V2/CustomerTestimonials_V2'));
const MenuBlockHomePage = loadable(() => import(/* webpackChunkName: "MenuBlockHomePage" */ './Partials/HomePageMenuBlock/MenuBlockHomePage'));
const PersonalizedHero = loadable(() => import(/* webpackChunkName: "PersonalizedHero" */ '../EnterpriseID/Common/PersonalizedHero'));

const HomePageBlock = ({
    block, ssrDeviceType, presentationFamily, brand, hasTopMargin, salesforceResponse, isFromPersonalizedContentBlock = false, setShowSkeleton,
}) => {
    if (!block) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            message: 'No block types have been passed into HomePageBlock.js',
        });
        return null;
    }
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    // Run a check to see that a block's references are deployed to the same environment
    if (isEmptyReference(blockObj, blockKey, 'HomePageBlock.js')) { setShowSkeleton(false); return null; }
    const categroyHPSilo = useSelector(getActiveABTest('category_hp_silo'));
    const isBot = useSelector(getIsBot);
    const ffIsHeroTestEnabled = useSelector(getFeatureFlag('is-hero-test-enabled'));

    switch (blockKey) {
        case 'universal_components':
            return (
                <ErrorBoundary boundaryName="Universal Component Error Boundary">
                    <UniversalComponent data={blockObj} />
                </ErrorBoundary>
            );

        // mobile only
        case 'mobile_tiles':
            return (
                <ErrorBoundary boundaryName="Mobile Tiles Error Boundary">
                    <GraphqlMobileCategory data={blockObj} presentationFamily={presentationFamily} />
                </ErrorBoundary>
            );

        case 'personalized_content':
            return (
                <ErrorBoundary boundaryName="Personalized Content Error Boundary">
                    <PersonalizedContent data={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} brand={brand} hasTopMargin={hasTopMargin} />
                </ErrorBoundary>
            );

        case 'offer_gateway':
            return (
                <ErrorBoundary boundaryName="Personalized Content Error Boundary">
                    <OfferGatewayBlock data={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} brand={brand} hasTopMargin={hasTopMargin} />
                </ErrorBoundary>
            );

        case 'personalized_widget':
            return (
                <ErrorBoundary boundaryName="Personalized Content Error Boundary">
                    <PersonalizedWidgetContainer data={blockObj} presentationFamily={presentationFamily} />
                </ErrorBoundary>
            );
        case 'gift_finder': {
            const isGiftFinderTestEnabled = useSelector(getFeatureFlag('is-gift-finder-test-enabled'));
            if (isGiftFinderTestEnabled) {
                return <GiftFinderABTestContainer data={blockObj.gift_finder_reference} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />;
            }
            // check the blockObj data for the exsistence of gift_finder_reference
            // if condition for homepage block ( will contain gift_finder_reference )
            // else ab testing block ( won't contain gift_finder_reference )
            const giftFinderReference = blockObj?.gift_finder_reference?.length
                ? blockObj.gift_finder_reference[0]
                : blockObj;

            const dateInfo = giftFinderReference
                ? blockObj?.gift_finder_reference?.[0]?.gift_finder_blocks?.find((gfBlock) => gfBlock.delivery_date)
                : blockObj?.gift_finder_blocks?.find((gfBlock) => gfBlock.delivery_date);

            const numDatesToDisplay = dateInfo?.delivery_date?.number_of_dates_to_show || 10;

            const cutOffTimes = dateInfo?.delivery_date?.today_cut_off_time;

            const exceptionDates = dateInfo?.delivery_date?.exception_delivery_date;

            const dateOptions = getNextNDates(numDatesToDisplay, cutOffTimes, exceptionDates);

            return (
                <ErrorBoundary boundaryName="Gift Finder Error Boundary">
                    <ConnectedGraphqlFindGiftFast dateInfo={dateInfo} data={giftFinderReference} dateOptions={dateOptions} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />
                </ErrorBoundary>
            );
        }

        case 'buy_again':
            return (
                <ErrorBoundary boundaryName="Buy again Error Boundary">
                    <PersonalizedRecentlyPurchasedContainer data={blockObj} />
                </ErrorBoundary>
            );

        // mobile only
        // TODO: REMOVE one dependency once merged with master
        case 'category_carousels':
            return (
                <ErrorBoundary boundaryName="Category Carousels Error Boundary">
                    <GraphqlHPCategoryCarousel data={blockObj} ssrDeviceType={ssrDeviceType} />
                </ErrorBoundary>
            );
        case 'category_carousel_silos': {
            const blockObjData = blockObj?.reference?.[0] ? blockObj.reference[0] : blockObj;
            const variant = blockObjData?.a_b_testing?.variant === 'CONTROL' || blockObjData?.a_b_testing?.variant === null;
            const showCategroyHPSilo = presentationFamily === 'flower' && ((variant && categroyHPSilo !== 'Variant') || (!variant && categroyHPSilo === 'Variant'));
            return (
                (showCategroyHPSilo || presentationFamily === 'food')
                && (
                    <ErrorBoundary boundaryName="Category Carousels Silos Error Boundary">
                        <GraphqlHPCategoryCarousel data={blockObjData} ssrDeviceType={ssrDeviceType} />
                    </ErrorBoundary>
                ));
        }

        case 'gift_finder_gfb':
            return (
                <ErrorBoundary boundaryName="Gift finder GFB Error Boundary">
                    <GiftFinder data={blockObj} />
                </ErrorBoundary>
            );

        case 'recently_viewed': {
            // load recently viewed or buy again from CMS show_module dropdown.
            const activeModule = blockObj?.show_module || [];
            let showRecentlyViewedModule = false;
            let showBuyAgainModule = false;
            if (activeModule?.length) {
                showRecentlyViewedModule = activeModule.includes('Recently Viewed');
                showBuyAgainModule = activeModule.includes('Buy Again');
            } else {
                showRecentlyViewedModule = true;
            }
            return (
                <>
                    {(showRecentlyViewedModule)
                        && (
                            <ErrorBoundary boundaryName="Recently Viewed Recommendations Error Boundary">
                                <RecentlyViewedRecsSFContainer blockObj={blockObj} />
                            </ErrorBoundary>
                        )}{(showBuyAgainModule)
                            && (
                                <ErrorBoundary boundaryName="Buy Again Recommendations Error Boundary">
                                    <BuyItAgainRecsSFContainer blockObj={blockObj} />
                                </ErrorBoundary>
                            )}
                </>
            );
        }

        case 'product_recommendations_service': {
            // TO-DO IN SF
            return null;
        }

        case 'offer_gatway_banner_tall': {
            return (
                <ErrorBoundary boundaryName="Offer Gateway Banner Container Error Boundary">
                    <OfferGatewayBannerContainer blockKey={blockKey} blockObj={blockObj} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />;
                </ErrorBoundary>
            );
        }

        case 'hero':
        case 'hero_basic':
            return (
                <ErrorBoundary boundaryName="Hero, Hero Basic Error Boundary">
                    <ConnectedHeroImage
                        fallback={(
                            <div
                                style={{
                                    width: blockObj?.skeleton?.width,
                                    height: blockObj?.skeleton?.height,
                                    background: '#e3e3e3',
                                }}
                            />
                        )}
                        data={blockObj}
                        ssrDeviceType={ssrDeviceType}
                    />
                </ErrorBoundary>
            );

        case 'hero_single': {
            if (ffIsHeroTestEnabled && !isBot) {
                if (blockObj?.reference?.[0]?.['_content_type_uid'] === 'hero_single_responsive') {
                    return (
                        <ComponentErrorBoundary function="HeroSingleResponsiveAbTestContainer" module="HeroSingleResponsive">
                            <HeroSingleResponsiveAbTestContainer data={blockObj?.reference} />
                        </ComponentErrorBoundary>
                    );
                }
            }
            const { image = null, video = null } = blockObj?.reference?.[0];
            return (
                <ComponentErrorBoundary module="HeroSingle" function="HomePageBlock">
                    {isFromPersonalizedContentBlock && !isVideoFormat(image?.content_type || video?.desktop?.mp4?.content_type || video?.desktop?.webm?.content_type)
                        ? <PersonalizedHero data={blockObj} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />
                        : <HeroSingle data={blockObj} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />}
                </ComponentErrorBoundary>
            );
        }
        case 'hero_single_responsive': {
            return (
                <ComponentErrorBoundary module="HeroSingleResponsive" function="HomePageBlock">
                    {isFromPersonalizedContentBlock ? <HeroSingleResponsive data={blockObj} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} /> : <HeroSingle data={blockObj} setShowSkeleton={setShowSkeleton} />}
                </ComponentErrorBoundary>
            );
        }

        case 'hero_three_section':
            return (
                <ErrorBoundary boundaryName="Hero three Section Error Boundary">
                    <ThirdsHero data={blockObj} />
                </ErrorBoundary>
            );

        case 'hero_interactive':
            return (
                <ErrorBoundary boundaryName="Hero Interactive Error Boundary">
                    <InteractiveHero data={blockObj} ssrDeviceType={ssrDeviceType} />
                </ErrorBoundary>
            );

        case 'generic_banner':
            return (
                <ErrorBoundary boundaryName="Generic Banner Error Boundary">
                    <BannerGeneric data={blockObj} hasTopMargin={hasTopMargin} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />
                </ErrorBoundary>
            );

        case '3_up':
            return (
                <ErrorBoundary boundaryName="3UP Error Boundary">
                    <ThreeUpFeature data={blockObj} />
                </ErrorBoundary>
            );

        case 'n_up':
            return (
                <ErrorBoundary boundaryName="NUP Error Boundary">
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <NUpFeature data={blockObj} />
                </ErrorBoundary>
            );

        case 'n_up_simple':
            return (
                <ErrorBoundary boundaryName="NUP Simple Error Boundary">
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <NUpSimple data={blockObj} />
                </ErrorBoundary>
            );
        // TODO: remove once category_tiers is stable
        case 'category_tiles':
            return (
                <ErrorBoundary boundaryName="Category Tiles Error Boundary">
                    <GraphqlHPTiers data={blockObj} />
                </ErrorBoundary>
            );
        case 'category_tiers':
            return (
                <ErrorBoundary boundaryName="Category Tiers Error Boundary">
                    <GraphqlHPTiers data={blockObj} categoryTiers />
                </ErrorBoundary>
            );

        case 'silos':
            return (
                <ErrorBoundary boundaryName="Silos Error Boundary">
                    <HPSilos data={blockObj} />
                </ErrorBoundary>
            );

        case 'seo':
            return (
                <ErrorBoundary boundaryName="SEO Error Boundary">
                    <GraphqlSeoFooter data={blockObj} />
                </ErrorBoundary>
            );

        case 'seo_copy': // GFB
            return (
                <ErrorBoundary boundaryName="SEO Copy Error Boundary">
                    <SeoBlock data={blockObj} />
                </ErrorBoundary>
            );

        case 'zone_10_banner':
            return (
                <ErrorBoundary boundaryName="Zone 10 banner Error Boundary">
                    <ZoneTenUI data={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />
                </ErrorBoundary>
            );

        case 'zone_10_banner_v2':
            return (
                <ErrorBoundary boundaryName="Zone 10 banner v2 Error Boundary">
                    <ZoneTenUI data={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />
                </ErrorBoundary>
            );

        case 'passport':
            return (
                <ErrorBoundary boundaryName="Passport Error Boundary">
                    <PassportBannerUI data={blockObj} />
                </ErrorBoundary>
            );

        case 'ugc':
            return (
                <ErrorBoundary boundaryName="UGC Error Boundary">
                    <UserGeneratedContent data={blockObj} />
                </ErrorBoundary>
            );

        case 'reviews':
            return (
                <ErrorBoundary boundaryName="Reviews Error Boundary">
                    <UserReviews data={blockObj} />
                </ErrorBoundary>
            );

        case 'ceo_banner':
            return (
                <ErrorBoundary boundaryName="CEO banner Error Boundary">
                    <CeoBannerUI data={blockObj} />
                </ErrorBoundary>
            );

        case 'trending_products':
            return (
                <ErrorBoundary boundaryName="Trending Products Error Boundary">
                    <TrendingProductsABContainer blockData={blockObj} />
                </ErrorBoundary>
            );

        case 'mobile_sms_signup':
            return (
                <ErrorBoundary boundaryName="Mobile sms signup Error Boundary">
                    <SmsSignup data={blockObj} />
                </ErrorBoundary>
            );

        case 'wyng':
            return (
                <ErrorBoundary boundaryName="WYNG Error Boundary">
                    <WyngModule data={blockObj} />
                </ErrorBoundary>
            );

        case 'subcollection':
            return (
                <ErrorBoundary boundaryName="Subcollection Error Boundary">
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <Subcollection data={blockObj} ssrDeviceType={ssrDeviceType} />
                </ErrorBoundary>
            );

        case 'video_module':
            return (
                <ErrorBoundary boundaryName="Video module Error Boundary">
                    <VideoModule data={blockObj} />
                </ErrorBoundary>
            );

        case 'link_list_module_mobile':
            return (
                <ErrorBoundary boundaryName="Link List module mobile Error Boundary">
                    <LinkList data={blockObj} type="footermenu" />
                </ErrorBoundary>
            );

        case 'dev_only':
            return (
                <ErrorBoundary boundaryName="Dev Only Error Boundary">
                    <ReactMarkdown source={blockObj.markdown} escapeHtml={false} />
                </ErrorBoundary>
            );

        // this will eventually feedback into Homepage block once a/b variant data has been retrieved
        case 'a_b_testing_block':
            return (
                <ErrorBoundary boundaryName="AB Testing block Error Boundary">
                    <ConnectedHomepageABTesting block={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />
                </ErrorBoundary>
            );

        case 'articles':
            return (
                <ErrorBoundary boundaryName="Articles Error Boundary">
                    <ArticleBlock data={blockObj} brand={brand} fullWidth />
                </ErrorBoundary>
            );
        case 'passport_banner':
            return (
                <ErrorBoundary boundaryName="Passport banner Error Boundary">
                    <PassportBannerHomepage data={blockObj} brand={brand} />
                </ErrorBoundary>
            );
        case 'homepage_slider': {
            const sliderData = blockObj.reference?.[0];
            return (
                <ErrorBoundary boundaryName="Homepage Slider Error Boundary">
                    <HomePageMainSlider data={sliderData} brand={brand} />
                </ErrorBoundary>
            );
        }
        case 'customer_testimonials':
            // creating fork in flow to include new version of component
            if (blockObj.reference[0]?._content_type_uid === 'customer_testimonials_v2') return <CustomerTestimonialsV2 data={blockObj} />;
            return (
                <ErrorBoundary boundaryName="Customer testimonials Error Boundary">
                    <TestimonialsPageBody block={blockObj} brand={brand} isCustomerTestimonial isProductTestimonial={false} />
                </ErrorBoundary>
            );
        case 'social_links':
            return (
                <ErrorBoundary boundaryName="Social links Error Boundary">
                    <SocialIconHomepageBlock data={blockObj} brand={brand} />
                </ErrorBoundary>
            );
        case 'menu_link_group':
            return (
                <ErrorBoundary boundaryName="Menu link group Error Boundary">
                    <MenuBlockHomePage block={blockObj} brand={brand} />
                </ErrorBoundary>
            );
        case 'celebrations_marketing_block':
            return (
                <ErrorBoundary boundaryName="Celebrations Marketing Block Error Boundary">
                    <CelebrationsMarketingBlock uid={blockObj?.entry_uid} contentid={blockObj?.content_type_id} />
                </ErrorBoundary>
            );
        case 'category_block':
            return (
                <ContentCategory brand={brand} block={blockObj} />
            );
        default:
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                message: `Unknown HomePage block type "${blockKey}" in HomePageBlock.js`,
            });
            return null;
    }
};

HomePageBlock.propTypes = {
    block: object.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    brand: string.isRequired,
    hasTopMargin: bool.isRequired,
    isFromPersonalizedContentBlock: bool,
    salesforceResponse: object,
    setShowSkeleton: func,
};

HomePageBlock.defaultProps = {
    isFromPersonalizedContentBlock: false,
    salesforceResponse: {},
    setShowSkeleton: noop,
};

export default HomePageBlock;
