// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferGatewayButton_marginTop__HkSeS{\n    margin-top: 12px;\n    align-items: end;\n}", "",{"version":3,"sources":["webpack://./src/app/components/GraphqlComponents/GraphqlCommonComponents/GenericDialog/OfferGateway/styles/OfferGatewayButton.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".marginTop{\n    margin-top: 12px;\n    align-items: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginTop": "OfferGatewayButton_marginTop__HkSeS"
};
module.exports = ___CSS_LOADER_EXPORT___;
