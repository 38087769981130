/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState }  from 'react';
import {
    bool, object, func, string,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import FlexibleShippingRadioButtons from './FlexibleShippingRadioButtons';
import GraphqlPortalLoader from '../../../../GraphqlCommonComponents/GraphqlPortalLoader';

const useStyles = makeStyles((theme) => ({
    modalHeaderContainer: {
    },
    modalHeader: {
        padding: '10px 0px',
        boxShadow: ' 0px 3px 3px 0px rgba(47, 47, 47, 0.15)',
        textTransform: 'uppercase',
        color: '#52297E',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '24px',
    },
    modalHeaderNoText: {
        height: '44px !important',
    },
    modalContainer: {
        [theme.breakpoints.up('sm')]: {
            top: '10% !important',
        },
        '& div': {
            alignItems: 'start',
            '& div': {
                [theme.breakpoints.down(600)]: {
                    height: 'auto',
                },
                maxHeight: '100%',
                margin: '0px !important',
                width: '100%',
                alignItems: 'start',
                borderRadius: '0px',
                maxWidth: '670px',
                position: 'relative',
                [theme.breakpoints.up('sm')]: {
                    height: 'auto',
                },
            },
        },
    },
    buttonCheckoutMobile: {
        border: 'none',
        borderRadius: '3px',
        width: '487px',
        height: '56px',
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        lineHeight: '26px',
        color: '#FFFFFF',
        backgroundColor: '#61C278',
        padding: '15px 0px',
        margin: '24px',
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            backgroundColor: '#61C278',
            color: theme?.palette?.white,
        },
        [theme.breakpoints.down(600)]: {
            position: 'fixed',
            bottom: 0,
            width: '95%',
            maxWidth: '487px',
        },
    },
    singleFlexOptionButton: {
        border: 'none',
        borderRadius: '3px',
        width: '370px',
        height: '52px',
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        lineHeight: '20px',
        color: '#FFFFFF',
        backgroundColor: '#61C278',
        padding: '15px 0px',
        margin: '20px',
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            backgroundColor: '#61C278',
            color: theme?.palette?.white,
        },
        [theme.breakpoints.down(600)]: {
            position: 'fixed',
            bottom: 0,
            width: '370px',

        },
    },
    buttonCheckout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    radioButtonsDiv: {
        height: '508px !important',
        [theme.breakpoints.down(1025)]: {
            height: '496px !important',

        },
        [theme.breakpoints.down(600)]: {
            height: '750px !important',

        },
    },
    radioButtonsDivTwo: {
        height: '514px !important',
        [theme.breakpoints.down(1025)]: {
            height: '504px !important',

        },
        [theme.breakpoints.down(600)]: {
            height: '750px !important',

        },
    },
    backToCalendarButton: {
        display: 'flex',
        marginLeft: '22px',
        marginTop: '22px',
        marginBottom: '12px',
        cursor: 'pointer',
        gap: '8px',
    },
    backToCalendarButtonText: {
        height: '12px',
        marginLeft: '10px',
        marginTop: '2px',
        top: '3px',
        fontSize: '14px !important',
    },
    titleContainer: {
        textAlign: 'center',
        fontFamily: 'lato',
        fontStyle: 'normal',
        fontWeight: 400,

        color: '#1F1F1F',
        height: '25px',

        [theme.breakpoints.down(600)]: {
            marginBottom: '20px !important',
        },
        '& p': {
            marginBottom: '0px',
            marginTop: '24px',
            fontFamily: 'lato',
            fontSize: '18px',
            lineHeight: '26px',
            // fontStyle: 'normal',
        },
        '& strong': {
            fontSize: '32px',
            lineHeight: '48px',
            [theme.breakpoints.down(600)]: {
                fontSize: '24px',
                lineHeight: '36px',
            },
        },
    },
    weatherBanner: {
        margin: '10px 35px 0px',
        [theme.breakpoints.down(400)]: {
            margin: '0px 30px !important',
        },
        [theme.breakpoints.down(376)]: {
            margin: '0px 23px !important',
        },
        [theme.breakpoints.down(361)]: {
            margin: '0px 25px !important',
        },
    },
    holidayBanner: {
        visibility: 'hidden',
        height: '60px',
        overflow: 'hidden',
    },
}));

const FlexibleShippingOptions = ({
    isOpen, handleFlexClose, dataObject, flexData, cmsData, flexAddToCart, shippingMessage, negativeSurchargeAmount, negativeSurchargeDates, weatherBanner, deviceType, location, brandId, flex,
}) => {
    const [loading, setLoading] = useState(false);
    const holidayBannerVisible = localStorage.getItem('holidayBanner');
    const classes = useStyles();
    const [selectedDate, setDate] = useState(flexData.find((flexDate) => flexDate?.defaultOption === 'Y') || flexData?.[0]);
    const handleDateChange = (e) => {
        // The whole flex date object needs to be stored in state.
        setDate(flexData.find((flexDay) => flexDay.optionCode === e.target.value));
    };
    const handleLoading = () => {
        if (location?.pathname.includes('add-ons')) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        }
    };
    const handleOnClick = () => {
        if (location?.pathname.includes('add-ons')) {
            flexAddToCart(selectedDate);
            handleLoading();
        } else {
            flexAddToCart(selectedDate);
            handleFlexClose();
        }
        return null;
    };
    const designatedBrand = brandId === '1001' || brandId === '1029' || brandId === '1010' || brandId === '1034';
    const flexModalHeaderCopy = cmsData?.entries?.[0]?.flex_modal_header_copy || '';
    const flexScreenTitle = flexData?.[0]?.flexMsg?.[6]?.msgText || 'Holiday Shipping Options';
    const negativeSurchargeMessage = shippingMessage && negativeSurchargeAmount && negativeSurchargeDates.length > 0;
    const imageUrl = cmsData?.entries?.[0]?.flex_banner_head_image?.url || 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt0cb6253d0865621a/65b1ef4f68334a2969c60287/2024_valentines_heart_v2.svg';
    const handleShowPopUp = () => {
        if (flex?.showFlexPopup === 'N') {
            return handleOnClick();
        }
        return (
            <Dialog
                open={isOpen}
                transitionDuration={300}
                TransitionComponent={Fade}
                className={`${classes.modalContainer}`}
                aria-describedby="flex-options"
                hideBackdrop
            >
                {loading && <GraphqlPortalLoader />}
                <div className={classes.modalHeaderContainer}>
                    {designatedBrand ? (
                        <div className={flexModalHeaderCopy ? `${classes.modalHeader} modalHeader` : `${classes.modalHeader} ${classes.modalHeaderNoText}`}>
                            {flexModalHeaderCopy ? <strong>{flexModalHeaderCopy}</strong> : ''}
                        </div>
                    ) : (
                        <div className={`${classes.modalHeader} modalHeader`}>
                            <strong>SELECT DELIVERY DATE</strong>
                        </div>
                    )}
                </div>
                <span
                    className={classes.backToCalendarButton}
                    onClick={handleFlexClose}
                    role="presentation"
                >
                    <ArrowBackIcon />
                    <div className={classes.backToCalendarButtonText}>Back To Calendar</div>
                </span>
                <div className={classes.titleContainer}>
                    <img src={imageUrl} alt="Valentines Logo" />
                    <ReactMarkdown
                        escapeHtml={false}
                        source={flexScreenTitle}
                    />
                </div>
                {weatherBanner && (
                    <span className={classes.weatherBanner}>
                        {weatherBanner}
                    </span>
                )}
                <div className={negativeSurchargeMessage ? classes.radioButtonsDivTwo : classes.radioButtonsDiv}>
                    <FlexibleShippingRadioButtons flexData={flexData} cmsData={cmsData} dataObject={dataObject} flexAddToCart={flexAddToCart} handleDateChange={handleDateChange} deviceType={deviceType} selectedDate={selectedDate} />
                    <Grid container className={classes.buttonCheckout}>
                        <button
                            onClick={() => { handleOnClick(); }}
                            className={classes.buttonCheckoutMobile}
                            type="button"
                            data-testid="confirmCheckout"
                        >
                            Save Delivery Date & Continue
                        </button>
                    </Grid>
                </div>
                {holidayBannerVisible && (
                    <span className={classes.holidayBanner} />
                )}
            </Dialog>
        );
    };
    return handleShowPopUp();
};

FlexibleShippingOptions.propTypes = {
    isOpen: bool.isRequired,
    dataObject: object.isRequired,
    handleFlexClose: func.isRequired,
    flexData: object.isRequired,
    cmsData: object.isRequired,
    flexAddToCart: func.isRequired,
    shippingMessage: string.isRequired,
    negativeSurchargeAmount: func.isRequired,
    negativeSurchargeDates: func.isRequired,
    weatherBanner: bool.isRequired,
    deviceType: string.isRequired,
    location: object.isRequired,
    brandId: string.isRequired,
    flex: object.isRequired,
};

export default FlexibleShippingOptions;
